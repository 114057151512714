<template>
  <div class="sub-cont">
    <div class="yk-rate-wrap">
      <!-- 상단 정보 영역-->
      <div class="rate-info-item">
        <div class="lt-item">
          <div class="info-header">
            <div class="biz">{{ employerName }}</div>
            <div
              class="status"
              :class="{
                wait:
                  workingStatus !== WORKING_STATUS[7].value &&
                  workingStatus !== WORKING_STATUS[8].value,
                hold: workingStatus === WORKING_STATUS[7].value, //지급보류
                succ: workingStatus === WORKING_STATUS[8].value, //지급확정
              }"
            >
              {{ WORKING_STATUS_NAME[workingStatus] }}
            </div>
            <!-- <div class="status wait">대기중</div>
            <div class="status succ">완료</div>
            <div class="status hold">지급보류</div> -->
            <div v-if="!isAddress" class="addressBox">
              <div @click="goLoadMap(employerName, latLon)">
                <a href="javascript:" class="btn-findingAWay"></a>
                <p>길찾기</p>
              </div>
              <div @click="addressCopy(workingAddress + workingAddressDetail)">
                <a href="javascript:" class="btn-copyAddress"></a>
                <p>주소복사</p>
              </div>
            </div>
          </div>
          <ul class="info-list01">
            <li>
              {{ workingAddress + ' ' + workingAddressDetail }}
            </li>
            <li v-if="isAddress" class="addressBox-con">
              <div class="addressBox">
                <div @click="goLoadMap(employerName, latLon)">
                  <img
                    src="~assets/image/ico/FindingAWay_M.png"
                    alt="모바일 길찾기 아이콘"
                  />
                  <span>길찾기</span>
                </div>
                <div
                  @click="addressCopy(workingAddress + workingAddressDetail)"
                >
                  <img
                    src="~assets/image/ico/link.png"
                    alt="주소 복사 아이콘"
                  />
                  <span>주소복사</span>
                </div>
              </div>
            </li>
            <li v-if="!isEmployee" class="rt-item" style="margin-top: 10px">
              <div class="key">근무자 : {{ employeeName }}</div>
            </li>
            <li>근무내용 : {{ positionName }}</li>
            <li>{{ `근무일자 : ${workingDate}` }}</li>
            <li>{{ `급여 : ${insertCommaToNumber(payAmt)}원` }}</li>
          </ul>
          <ul class="info-list02">
            <!-- 할당제 -->
            <li v-if="isWorkingTypeRoom">
              <i class="el-icon-time"></i>
              <span>할당제</span>
            </li>
            <li v-if="isWorkingTypeRoom">{{ `룸 ${roomCnt}개` }}</li>

            <!-- 시간제 -->
            <li v-if="!isWorkingTypeRoom">
              <i class="el-icon-time"></i>
              <span>시간제</span>
            </li>
            <li v-if="!isWorkingTypeRoom">
              {{ `준수 근로시간 (${workingStTime} ~ ${workingEdTime})` }}
              <!-- 준수 근로시간 ( 오전 9:00 ~ 오후 6:00 ) -->
            </li>
          </ul>
        </div>
      </div>

      <!-- 카드 영역-->
      <div
        class="yk-card-wrap"
        v-if="
          workingStatus !== WORKING_STATUS[6].value &&
          workingStatus !== WORKING_STATUS[9].value
        "
      >
        <div class="lt-item">
          <div class="key">
            <i class="el-icon-time"></i>
            <span> {{ isWorkingTypeRoom ? '작업룸' : '출근시간' }} </span>
          </div>

          <ul>
            <!-- 할당제 UI -->
            <li v-if="isWorkingTypeRoom">
              <strong>0</strong>
              <span>개</span>
            </li>

            <li>
              <!-- <span>오전</span> -->
              <strong class="pl">{{ signStTime }}</strong>
            </li>
          </ul>

          <div class="card-btn-wrap">
            <button
              v-if="isEmployee"
              type="button"
              @click="setWorkingStatus(WORKING_STATUS_VALUE.WORK_STAMP)"
            >
              출근도장
            </button>
            <button
              v-else
              type="button"
              @click="setWorkingStatus(WORKING_STATUS_VALUE.WORK_CONFIRM)"
            >
              출근확인
            </button>
          </div>
        </div>
        <div class="rt-item">
          <div class="key">
            <i class="el-icon-time"></i>
            <span>퇴근시간</span>
          </div>
          <ul>
            <!-- 할당제 UI -->
            <li v-if="isWorkingTypeRoom">
              <strong>{{ roomCnt }}</strong>
              <span>개</span>
            </li>

            <li>
              <!-- <span>오전</span> -->
              <strong class="pl">{{ signEdTime }}</strong>
            </li>
          </ul>
          <div class="card-btn-wrap">
            <button
              v-if="isEmployee"
              type="button"
              @click="setWorkingStatus(WORKING_STATUS_VALUE.LEAVE_STAMP)"
            >
              퇴근도장
            </button>
            <button
              v-else
              type="button"
              @click="setWorkingStatus(WORKING_STATUS_VALUE.LEAVE_CONFIRM)"
            >
              퇴근확인
            </button>
          </div>
        </div>
      </div>
      <div
        class="rate-info-item"
        v-else-if="workingStatus === WORKING_STATUS[9].value"
      >
        <div class="lt-item">
          <div class="info-msg">
            해당 근무는&nbsp;<span>취소</span>되었습니다.
          </div>
        </div>
      </div>
      <div class="rate-info-item" v-else>
        <div class="lt-item">
          <div class="info-msg">
            해당 근무는&nbsp;<span>노쇼</span>처리 되었습니다.
          </div>
        </div>
      </div>

      <!-- 근무 평가-->
      <div
        class="yk-rate-footer-wrap"
        v-if="
          workingStatus === WORKING_STATUS[5].value ||
          workingStatus === WORKING_STATUS[8].value
        "
      >
        <div class="rate-tit">근무평가</div>
        <div class="rate-form-item">
          <div class="lt-item">
            <div class="label">근무는 만족 하셨나요?</div>
            <div id="rateYo" class="rating" @click="setGradeVal"></div>
            <div class="msg">선택하세요</div>
          </div>
          <div class="rt-item">
            <div class="label">남기실 말씀이 있나요?</div>
            <textarea
              v-if="isEmployee"
              v-model="employerEvalMsg"
              class="textarea"
              ref="review"
            ></textarea>
            <textarea
              v-else
              v-model="employeeEvalMsg"
              class="textarea"
              ref="review"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isEmployee" class="yk-rate-btn-wrap">
      <button
        type="button"
        class="btn-succ"
        @click="eval"
        v-if="workingStatus === WORKING_STATUS[5].value && isNotEvalEmployer"
      >
        리뷰등록
      </button>
      <button type="button" class="btn-hold" @click="goBackPage">목록</button>
    </div>
    <div v-else class="yk-rate-btn-wrap">
      <button
        type="button"
        class="btn-succ"
        @click="eval(WORKING_STATUS_VALUE.PAY_CONFIRM)"
        v-if="workingStatus === WORKING_STATUS[5].value"
      >
        지급확정
      </button>
      <!-- <button
        type="button"
        class="btn-hold"
        @click="eval(WORKING_STATUS_VALUE.PAY_HOLD)"
      >
        지급보류
      </button> -->
      <button
        type="button"
        class="btn-noshow"
        @click="eval(WORKING_STATUS_VALUE.NO_SHOW)"
        v-if="workingStatus !== WORKING_STATUS[8].value"
      >
        노쇼
      </button>
      <button type="button" class="btn-hold" @click="goBackPage">목록</button>
    </div>
  </div>
</template>

<script>
import {
  getAttendanceDetailForEmployer,
  getAttendanceDetailForEmployee,
  putAttendanceStatus, //근태 상태 변경
  putEvalAttendanceToEmployer, //근태 평가
  putEvalAttendanceToEmployee, //근태 평가
} from 'Api/modules'
import {
  WORKING_STATUS_VALUE,
  WORKING_STATUS,
  WORKING_STATUS_NAME,
} from 'Configs/workingStatus'
export default {
  data() {
    return {
      WORKING_STATUS_VALUE: Object.freeze(WORKING_STATUS_VALUE),
      WORKING_STATUS: Object.freeze(WORKING_STATUS),
      WORKING_STATUS_NAME: Object.freeze(WORKING_STATUS_NAME),
      workingId: '',
      // evalMessage: '',
      // evalGrade: '',
      positionName: '',
      employerName: '', //구인,구직자의 경우 사용
      employeeName: '', //구인자의 경우 사용
      workingAddress: '',
      workingAddressDetail: '',
      workingType: 'TIME',
      workingDate: '',
      workingStTime: '',
      workingEdTime: '',
      roomCnt: 0,
      signStTime: '',
      signEdTime: '',
      confirmStTime: '',
      confirmEdTime: '',
      workingStatus: '',
      employerGrade: null, //구직자의 경우 사용
      employeeGrade: null, //구인자의 경우 사용
      employerEvalMsg: '', //구직자의 경우 사용
      employeeEvalMsg: '', //구인자의 경우 사용
      jobContractId: '',
      isNotEvalEmployer: false,
      payAmt: '',
      latLon: '',
      isAddress: false,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    isWorkingTypeRoom() {
      return this.workingType === 'ROOM'
    },
  },
  watch: {
    window: {
      deep: true,

      handler() {
        if (this.window.width < 1023) {
          this.isAddress = true
        } else {
          this.isAddress = false
        }
        console.log(this.window.width)
      },
    },
  },
  methods: {
    // 뒤로가기
    goBackPage() {
      this.$router.go(-1)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    addressCopy(text) {
      var t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = `${text}` // 원하는 텍스트
      t.select()
      document.execCommand('copy')
      this.$toasted.show('클립보드에 주소가 복사되었습니다.')
    },
    goLoadMap(hotelName, latLon) {
      console.info(hotelName, latLon)
      //	var url = 'http://map.daum.net/link/to/'+clubName+','+latlng;
      var url = 'https://map.daum.net/link/map/' + hotelName + ',' + latLon
      if (latLon == '') {
        url = 'https://map.daum.net/link/search/' + hotelName
      }
      window.open(url)
    },
    //근태상태 변경
    async setWorkingStatus(workingStatus) {
      try {
        // const res = await putAttendanceStatus({
        await putAttendanceStatus({
          workingId: this.workingId,
          userId: this.account.userId,
          userType: this.account.userType,
          workingStatus,
        })

        if (
          workingStatus === WORKING_STATUS_VALUE.WORK_CONFIRM ||
          workingStatus === WORKING_STATUS_VALUE.LEAVE_CONFIRM
        )
          this.$toasted.show(`${WORKING_STATUS_NAME[workingStatus]} 했습니다.`)

        await this.fetchAttendanceDetail()
      } catch (e) {
        console.error(e)
      }
    },

    //근태평가
    async eval(workingStatus) {
      // todo:: isEmployee 못 가져옴.
      if (this.isEmployee) {
        if (!this.employerGrade || this.employerGrade === '0') {
          this.$toasted.error('별점을 매겨주세요.')
          return false
        }
        if (!this.employerEvalMsg) {
          this.$toasted.error('근무 리뷰를 작성해주세요.')
          this.$refs['review'].focus()
          return false
        }
        //구직자가 리뷰등록
        await putEvalAttendanceToEmployer({
          workingId: this.workingId,
          userId: this.userId,
          userType: this.userType,
          evalMessage: this.employerEvalMsg,
          evalGrade: this.employerGrade,
        })
      } else {
        //구인자가 근태평가 : 확정/보류/노쇼
        if (workingStatus === WORKING_STATUS[8].value) {
          /*
          if (!this.employeeGrade || this.employeeGrade === '0') {
            this.$toasted.error('별점을 매겨주세요.')
            return false
          }
          if (!this.employeeEvalMsg) {
            this.$toasted.error('근무 리뷰를 작성해주세요.')
            this.$refs['review'].focus()
            return false
          }*/
        }
        if (!this.userType) {
          await putEvalAttendanceToEmployee({
            workingId: this.workingId,
            userId: this.userId,
            userType: this.userType,
            evalMessage: this.employeeEvalMsg,
            evalGrade: this.employeeGrade,
            workingStatus: workingStatus,
          })
        } else {
          await putEvalAttendanceToEmployee({
            workingId: this.workingId,
            userId: this.userId,
            userType: this.userType,
            evalMessage: this.employeeEvalMsg,
            evalGrade: this.employeeGrade,
            workingStatus: workingStatus,
          })
        }
      }
      this.$toasted.show('평가가 완료되었습니다.')
      setTimeout(() => {
        this.goBackPage()
      }, 300)
      // todo: 목록으로 이동 필요
      // this.$router.push('/attendance')
      // await this.fetchAttendanceDetail()
    },

    //근태 정보 조회
    async fetchAttendanceDetail() {
      this.workingId = this.$route.params.jobId
      let res
      if (this.isEmployee) {
        res = await getAttendanceDetailForEmployee({
          employeeId: this.account.userId,
          workingId: this.workingId,
        })
      } else {
        res = await getAttendanceDetailForEmployer({
          employeeId: this.account.userId,
          workingId: this.workingId,
        })
      }

      this.employerName = res.employerName //구인,구직자의 경우 사용
      this.employeeName = res.employeeName //구인자의 경우 사용
      this.workingAddress = res.workingAddress
      this.workingAddressDetail = res.workingAddressDetail
      this.workingType = res.workingType || 'TIME'
      this.workingDate = res.workingDate
      this.workingStTime = res.workingStTime
      this.workingEdTime = res.workingEdTime
      this.roomCnt = res.roomCnt || 0
      this.signStTime = res.signStTime || ''
      this.signEdTime = res.signEdTime || ''
      this.confirmStTime = res.confirmStTime
      this.confirmEdTime = res.confirmEdTime
      this.workingStatus = res.workingStatus
      this.employerGrade = res.employerGrade || '0' //구직자의 경우 사용
      this.employeeGrade = res.employeeGrade || '0' //구인자의 경우 사용
      this.employerEvalMsg = res.employerEvalMsg //구직자의 경우 사용
      this.employeeEvalMsg = res.employeeEvalMsg //구인자의 경우 사용
      this.jobContractId = res.jobContractId
      this.positionName = res.positionName
      this.payAmt = res.payAmt
      this.latLon = res.latLon
      if (this.isEmployee && !(this.employerGrade && this.employerEvalMsg)) {
        this.isNotEvalEmployer = true
      }

      // const grade = this.employerGrade || this.employeeGrade || 0
      const grade =
        this.employerGrade !== '0'
          ? this.employerGrade
          : this.employeeGrade !== '0'
          ? this.employeeGrade
          : '0'

      //@TODO 근무평가를 하는 시점과, 받고난 시점의 구별을 뭐로 하는지?
      this.$nextTick(() => {
        window.$('#rateYo').rateYo({
          rating: grade / 20, //1.5,
          spacing: '5px',
          precision: 0,
          numStars: 5,
          // halfStar: true,
          fullStar: true,
          normalFill: '#e4e4e4',
          ratedFill: '#ff5476',
        })
      })
    },

    //근무평가 점수
    setGradeVal() {
      window
        .$('#rateYo')
        .rateYo()
        .on('rateyo.change', (e, data) => {
          let rating = data.rating
          //구직회원이면, 구인회원 평가함
          const ratingVal = this.getRateGradeVal(rating)
          if (this.isEmployee) {
            this.employerGrade = ratingVal
          } else {
            this.employeeGrade = ratingVal
          }
        })
    },
    //rate 로 평가점수 환산
    getRateGradeVal(rating) {
      if (rating === 1) return '20'
      else if (rating === 2) return '40'
      else if (rating === 3) return '60'
      else if (rating === 4) return '80'
      else if (rating === 5) return '100'
    },
  },
  async created() {
    await this.fetchAttendanceDetail()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  updated() {
    this.$nextTick(() => {
      this.setGradeVal()
    })
  },
}
</script>

<style lang="scss" scoped>
.yk-rate-wrap .rate-info-item .lt-item .info-msg {
  display: flex;
  margin: 0 10px 40px 0;
  font-size: 20px;
  color: #111;
  font-weight: bold;
  justify-content: center;
}
.info-msg > span {
  display: flex;
  font-size: 20px;
  color: red;
  font-weight: bold;
  justify-content: center;
}
.addressBox {
  div {
    a {
      &.btn-findingAWay {
        background: url('~assets/image/main/FindingAWay.png') no-repeat center;
        background-size: 50px auto;
      }
      &.btn-copyAddress {
        background: url(~assets/image/ico/ico-link.svg) no-repeat center;
        background-size: 40px auto;
      }
    }
  }
}
</style>

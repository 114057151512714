export const WORKING_STATUS_VALUE = {
  WAIT: 'WAIT', //출근전
  WORK_STAMP: 'WORK_STAMP', //출근도장
  WORK_CONFIRM: 'WORK_CONFIRM', //출근확인
  LEAVE_STAMP: 'LEAVE_STAMP', //퇴근도장
  LEAVE_CONFIRM: 'LEAVE_CONFIRM', //퇴근확인
  NO_SHOW: 'NO_SHOW', //노쇼
  PAY_HOLD: 'PAY_HOLD', //지급보류
  PAY_CONFIRM: 'PAY_CONFIRM', //지급확정
  CANCEL: 'CANCEL', //취소
}

export const WORKING_STATUS_NAME = {
  WAIT: '출근전',
  WORK_STAMP: '출근도장',
  WORK_CONFIRM: '출근확인',
  LEAVE_STAMP: '퇴근도장',
  LEAVE_CONFIRM: '퇴근확인',
  NO_SHOW: '노쇼',
  PAY_HOLD: '지급보류',
  PAY_CONFIRM: '지급확정',
  CANCEL: '취소',
}

export const WORKING_STATUS = [
  {
    name: '전체',
    value: '',
  },
  {
    name: '출근전',
    value: 'WAIT',
  },
  {
    name: '출근도장',
    value: 'WORK_STAMP',
  },
  {
    name: '출근확인',
    value: 'WORK_CONFIRM',
  },
  {
    name: '퇴근도장',
    value: 'LEAVE_STAMP',
  },
  {
    name: '퇴근확인',
    value: 'LEAVE_CONFIRM',
  },
  {
    name: '노쇼',
    value: 'NO_SHOW',
  },
  {
    name: '지급보류',
    value: 'PAY_HOLD',
  },
  {
    name: '지급확정',
    value: 'PAY_CONFIRM',
  },
  {
    name: '취소',
    value: 'CANCEL',
  },
]
